import React from "react";
import _ from "lodash";
import { graphql, Link } from "gatsby";
import tw, { styled } from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Icon from "@shared/components/Icon";
import Header from "@shared/components/Header";
import PaginatedTable from "@shared/components/PaginatedTable";
import { MAIN_COLOR, SITE } from "../config";

const Container = styled.div`
  ${tw`p-5`}
`;
const PlayerCell = styled.div`
  ${tw`flex items-center`}
`;
const PlayerAvatarContainer = styled.div`
  ${tw`min-w-[40px] w-10 h-10 rounded-full overflow-hidden`}
  & > .material-icons {
    ${tw`text-4xl px-0.5`}
  }
`;
const PlayerName = styled.div`
  ${tw`ml-2 font-bold`}
`;
const TableTitle = styled.div`
  ${tw`text-gray-600 font-bold mt-4`}
`;
const TableContainer = styled.div`
  ${tw`pb-2 mt-4 min-h-[50px] overflow-x-auto`}
`;

const ContentContainer = styled.div`
  ${tw`w-full mt-4`}
`;

const StyledLink = styled(Link)`
  ${tw`hover:underline text-[#EC1F27]`}
`;

const schoolTypeOrder = ["NCAA D1", "NCAA D2", "NCAA D3", "NAIA", "NJCAA"];

const getSchoolTypeRank = (school) => {
  const schools = school.split(/[;,'.]+/).map((s) => s.trim());
  const ranks = schools
    .map((s) => schoolTypeOrder.indexOf(s))
    .filter((rank) => rank !== -1);

  if (ranks.length > 0) {
    return Math.min(...ranks);
  }

  return schoolTypeOrder.length + 1;
};

const alumniHeaders = [
  {
    Header: "Name",
    accessor: "name",
    sortType: "basic",
    disableSortBy: true,
    width: 200,
    Cell: ({ row }) => {
      return (
        <PlayerCell>
          {/* <PlayerAvatarContainer>
            {_.isNil(row.original.avatar) ? (
              <Icon name="account_circle" />
            ) : (
              <GatsbyImage
                image={getImage(row.original.avatar)}
                style={{ width: "100%", height: "100%" }}
                imgStyle={{ width: "100%", height: "100%" }}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="avatar"
              />
            )}
          </PlayerAvatarContainer> */}
          <PlayerName>{row.original.name}</PlayerName>
        </PlayerCell>
      );
    },
  },
  {
    Header: "Grad Class",
    accessor: "gradYear",
    sortType: "basic",
    width: 100,
  },
  {
    Header: "Organization",
    accessor: "organization",
    sortType: (rowA, rowB, columnId) => {
      const schoolRankA = getSchoolTypeRank(rowA.values[columnId]);
      const schoolRankB = getSchoolTypeRank(rowB.values[columnId]);
      return schoolRankA - schoolRankB;
    },
    width: 200,
  },
  {
    Header: "College",
    accessor: "college",
    sortType: "basic",
    disableSortBy: true,
    width: 183,
  },
];

const mlbHeaders = [
  {
    Header: "Player",
    accessor: "name",
    sortType: "basic",
    width: 200,
    Cell: ({ row }) => {
      return (
        <PlayerCell>
          {/* <PlayerAvatarContainer>
            {_.isNil(row.original.avatar) ? (
              <Icon name="account_circle" />
            ) : (
              <GatsbyImage
                image={getImage(row.original.avatar)}
                style={{ width: "100%", height: "100%" }}
                imgStyle={{ width: "100%", height: "100%" }}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="avatar"
              />
            )}
          </PlayerAvatarContainer> */}
          <PlayerName>{row.original.name}</PlayerName>
        </PlayerCell>
      );
    },
  },
  {
    Header: "Position",
    accessor: (row) => row.positions.map((p) => getPosition(p)).join(", "),
    sortType: "basic",
    width: 283,
  },
  {
    Header: "Organization",
    accessor: "organization",
    sortType: "basic",
    width: 250,
  },
];

const teamCanadaHeaders = [
  {
    Header: "Name",
    accessor: "name",
    sortType: "basic",
    width: 200,
    Cell: ({ row }) => {
      return (
        <PlayerCell>
          {/* <PlayerAvatarContainer>
            {_.isNil(row.original.avatar) ? (
              <Icon name="account_circle" />
            ) : (
              <GatsbyImage
                image={getImage(row.original.avatar)}
                style={{ width: "100%", height: "100%" }}
                imgStyle={{ width: "100%", height: "100%" }}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="avatar"
              />
            )}
          </PlayerAvatarContainer> */}
          <PlayerName>{row.original.name}</PlayerName>
        </PlayerCell>
      );
    },
  },
  {
    Header: "Position",
    accessor: (row) => row.positions.map((p) => getPosition(p)).join(", "),
    sortType: "basic",
    width: 283,
  },
  {
    Header: "College",
    accessor: "college",
    sortType: "basic",
    width: 200,
  },
];

const AlumniPage = ({ data, location }) => {
  const { seo, hero, tabs, title } = data.markdownRemark.frontmatter;
  // roster
  const pathname = _.get(location, "pathname") || "";
  const isTeamCanadaPage = pathname.includes("team-canada");
  const isMLBPage = pathname.includes("mlb");
  const isCollegePlacementPage = pathname.includes("college-placement");
  const isCanadianPlacementPage = pathname.includes("canadian-futures");
  const players = data.playerMds.edges.map((edge) => {
    const {
      // avatar,
      playerNumber,
      name,
      weight,
      height,
      team,
      gradYear,
      positions,
      college,
      bats,
      throws,
      homeTown,
      organization,
      showcases = [],
    } = edge.node.frontmatter;
    const slug = edge.node.fields.slug;
    return {
      // avatar,
      playerNumber,
      name,
      weight,
      height,
      team,
      gradYear,
      positions,
      college,
      bats,
      throws,
      homeTown,
      slug,
      organization,
      showcases,
    };
  });
  const dawgsCollegePlacement = players.filter(
    (p) => p.gradYear && p.college && p.organization
  );

  const mlbPlayers = players.filter((p) =>
    (p.showcases || []).includes("MLB Draft Picks")
  );
  const t12Players = players.filter((p) => (p.showcases || []).includes("T12"));
  const teamCanadaPlayers = players.filter((p) =>
    (p.showcases || []).includes("Team Canada")
  );

  const collegePlacementDefaultSort = [
    {
      id: "gradYear",
      desc: true,
    },
    {
      id: "organization",
      desc: false,
    },
  ];

  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        {title && (
          <Header
            title={title}
            heroData={hero}
            tabsData={tabs}
            color={MAIN_COLOR}
            site={SITE}
          />
        )}
        <ContentContainer
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />

        {isCollegePlacementPage && (
          <>
            <TableTitle>Dawgs College Placement</TableTitle>
            <TableContainer>
              <PaginatedTable
                items={dawgsCollegePlacement}
                headers={alumniHeaders}
                color={MAIN_COLOR}
                defaultSortBy={collegePlacementDefaultSort}
                pageSize={25}
              />
            </TableContainer>
          </>
        )}

        {isMLBPage && (
          <>
            <TableTitle>
              Dawgs Selected in the Major League Baseball Amateur Draft
            </TableTitle>
            <TableContainer>
              <PaginatedTable
                items={mlbPlayers}
                headers={mlbHeaders}
                color={MAIN_COLOR}
                pageSize={25}
              />
            </TableContainer>
          </>
        )}
        {isTeamCanadaPage && (
          <>
            <TableTitle>Dawgs Selected to Team Canada</TableTitle>
            <TableContainer>
              <PaginatedTable
                items={teamCanadaPlayers}
                headers={teamCanadaHeaders}
                color={MAIN_COLOR}
                pageSize={25}
              />
            </TableContainer>
          </>
        )}
        {isCanadianPlacementPage && (
          <>
            <TableTitle>Dawgs Selected to T12</TableTitle>
            <TableContainer>
              <PaginatedTable
                items={t12Players}
                headers={teamCanadaHeaders}
                color={MAIN_COLOR}
                pageSize={25}
              />
            </TableContainer>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default AlumniPage;

export const pageQuery = graphql`
  query AlumniPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        seo {
          title
          description
        }
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
        title
      }
    }
    playerMds: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "player-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            # avatar {
            #   childImageSharp {
            #     gatsbyImageData(layout: FULL_WIDTH)
            #   }
            # }
            # playerNumber
            # age
            name
            gradYear
            # weight
            # height
            # team
            # yr
            organization
            showcases
            positions
            college
            # bats
            # throws
            homeTown
          }
        }
      }
    }
  }
`;

const getPosition = (position) => {
  let term = "";
  switch (position) {
    case "Right-handed Pitcher":
      term = "RHP";
      break;
    case "Left-handed Pitcher":
      term = "LHP";
      break;
    case "Catcher":
      term = "C";
      break;
    case "Infield":
      term = "INF";
      break;
    case "Outfield":
      term = "OF";
      break;
    default:
      break;
  }
  return term;
};
